import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import Seo from "components/seo";
import Banner from "sections/banner";
import Order from "sections/order";
import Layout from "components/layout";
import Testimonials from "sections/testimonials";
import Menu from "sections/menu";
import About from "sections/about";

import "rc-drawer/assets/index.css";
import "swiper/swiper-bundle.min.css";

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <Seo
          title="Josephine's Bakehouse"
          description="Gluten free, dairy free baked goods from Sebastopol, California"
        />
        <Banner />
        <Menu />
        <Order />
        <Testimonials />
        <About />
      </Layout>
    </StickyProvider>
  );
}
