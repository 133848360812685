/** @jsx jsx */
import { jsx, Box, Heading, Container, Grid, Text } from "theme-ui";
import MenuCard from "components/cards/menu-card";

import thumb1 from "assets/images/cinnamonraisinSlices.jpg";
import thumb2 from "assets/images/cookies.jpg";
import thumb3 from "assets/images/cinnamonraisin.jpg";

const MENU_DATA = {
  heading: {
    title: "The Bakehouse",
    text: "Baked goods from our menu",
  },
  menu: [
    {
      id: 1,
      thumb: thumb1,
      title: "Sourdough Breads",
      items: [
        "Rustic white bread",
        "Rosemary bread",
        "Caraway teff bread",
        "Oat bread",
        "Cinnamon raisin bread",
      ],
    },
    {
      id: 2,
      thumb: thumb2,
      title: "Sweet and Savory",
      items: ["Vegan sourdough chocolate chip cookies", "Pizza crusts"],
    },
    {
      id: 3,
      thumb: thumb3,
      title: "Flour Blends",
      items: ["GF all purpose flour"],
    },
  ],
};

const Menu = () => {
  const { heading, menu } = MENU_DATA;
  return (
    <Box as="section" id="menu" sx={styles.section} variant="section.menu">
      <Container>
        <Box sx={styles.heading}>
          <Heading as="h2">{heading.title}</Heading>
          <Text as="p">{heading.text}</Text>
        </Box>
        <Grid gap={styles.grid.gap} columns={styles.grid.gridTemplateColumns}>
          {menu?.map((post) => (
            <MenuCard key={post.id} post={post} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Menu;

const styles = {
  heading: {
    textAlign: "center",
    mb: ["35px", null, null, "55px", null, "60px", "85px", "95px", "110px"],
    h2: {
      fontFamily: "archivo",
      fontSize: ["24px", "28px", "32px", "36px", null, "40px"],
      fontWeight: 600,
      lineHeight: 1.4,
      color: "#0F2137",
      mb: ["12px", null, "15px", null, "20px"],
    },
    p: {
      fontSize: ["15px", null, null, null, "16px", "17px"],
      lineHeight: [1.85, null, 1.9, null, 2, 2.47],
      color: "text",
      mt: [3, null, null, "18px"],
    },
  },
  grid: {
    gap: 30,
    display: ["grid", null, null, "block", "grid"],
    gridTemplateColumns: [
      "repeat(1, 1fr)",
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
    m: [0, 0, 0, "0 -15px", 0],
  },
};
