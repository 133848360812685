import React from "react";
import Img from "gatsby-image";
import { Button, Container, Box, Heading, Text } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

const BANNER_DATA = {
  title: "Handcrafted & Gluten Free",
  text: "Our naturally leavened, gluten free bread is made in small batches",
  button: {
    path: "order",
    label: "Order",
  },
};

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bread_packaged.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 740) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { title, text, button } = BANNER_DATA;
  return (
    <Box as="section" id="banner" sx={styles.section}>
      <Container sx={styles.container}>
        <Box sx={styles.content}>
          <Heading as="h1">{title}</Heading>
          <Text as="p">{text}</Text>
          <Box sx={styles.btnWrap}>
            <ScrollLink
              activeClass="active"
              sx={styles.btn}
              to={button.path}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <Button sx={styles.btn}>{button.label}</Button>
            </ScrollLink>
          </Box>
        </Box>

        <Box sx={styles.sectionImage}>
          <Box sx={styles.thumbnail}>
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              alt="Banner Thumbnail"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;

const styles = {
  section: {
    overflow: "hidden",
    pt: ["115px", null, null, "140px", "150px", "170px", "185px"],
    pb: ["60px", "75px", null, "90px", "110px", "120px", "140px", "160px"],
  },
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: ["column", null, null, "row"],
  },
  content: {
    maxWidth: ["100%", null, null, "355px", "460px", "545px", null, "590px"],
    textAlign: ["center", null, null, "left"],
    h1: {
      fontSize: ["28px", "32px", null, "34px", "40px", "48px", "54px", "58px"],
      lineHeight: [1.4, null, null, 1.35],
      color: "heading",
      fontFamily: "archivo",
      letterSpacing: "-1.5px",
      fontWeight: "body",
      mx: ["0", null, null, "auto", "0"],
    },
    p: {
      fontSize: ["15px", null, null, null, "16px", "17px"],
      lineHeight: [1.85, null, 1.9, null, 2, 2.47],
      color: "text",
      mt: [3, null, null, "18px"],
      pr: [0, null, null, null, null, null, null, "50px"],
    },
  },
  btnWrap: {
    display: "flex",
    alignItems: "center",
    mt: ["25px", null, null, "30px", "35px", "50px"],
    justifyContent: ["center", null, null, "flex-start"],
  },
  btn: {
    backgroundColor: "heading_secondary",
    borderRadius: "7px",
    lineHeight: 1,
    fontSize: ["13px", "14px", "15px"],
    padding: ["14px 20px 13px", "14px 25px 13px", "17px 30px 15px"],
    fontWeight: 700,
    display: "inline-flex",
    alignItems: "center",
    textTransform: "uppercase",
    color: "#ffffff",
    transition: "all 300ms ease",
    "&:hover": {
      opacity: 0.8,
    },
  },
  sectionImage: {
    mt: ["40px", null, null, 0],
    pl: [0, null, null, "30px", 0],
    position: "relative",
    right: ["auto", null, null, null, "-10px", "-50px", "-70px"],
    width: [
      "100%",
      null,
      null,
      "calc(100% - 355px)",
      "calc(100% - 460px)",
      "calc(100% - 545px)",
      null,
      "calc(100% - 590px)",
    ],
    textAlign: ["center", null],
  },
  thumbnail: {
    maxWidth: "740px",
    ml: "auto",
  },
};
