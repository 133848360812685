import React from "react";
import { Box, Container, Grid, Heading, Text, Image } from "theme-ui";

import img1 from "assets/images/lemonRosemary.jpg";

const About = () => {
  return (
    <Box as="section" id="about" sx={styles.about}>
      <Container>
        <Grid sx={styles.row}>
          <Box sx={styles.col}>
            <Box sx={styles.content}>
              <Heading as="h3">Our Story</Heading>
              <Text as="p">
                Our story began with Josephine, a joyful little girl who was
                diagnosed with celiac disease at the age of five. Christine, her
                mother, began the quest of making a healthy gluten free
                sourdough bread that's healthy for her family. After many years
                of modifying her recipes, Christine has created a gluten free
                sourdough bread that is healthy, great tasting, and has a
                wonderful texture. Josephine's Bakehouse is a dedicated gluten
                free kitchen and licensed Cottage Food Operation in Sebastopol,
                California.
              </Text>
            </Box>
            <Box sx={styles.content}>
              <Heading as="h3">Our Bread</Heading>
              <Text as="p">
                Our sourdough gluten free breads are naturally leavened. The
                process begins with a bulk fermentation of 9 hours and then it's
                incorporated in the dough that proofs for an additional 9 hours.
                We believe in high quality ingredients, and do not use
                preservatives, gums or psyllium husk. We use mostly organic
                flours that are purchased locally.
              </Text>
            </Box>
            <Box sx={styles.content}>
              <Heading as="h3">Keeping & Storing</Heading>
              <Text as="p">
                Our bread is best kept at room temperature for up to two days or
                you can slice, freeze, and toast for up to a month. For optimal
                chew and crunch, we recommend toasting straight from the
                freezer. We suggest slicing the bread at 1cm width; a half loaf
                will have 12 slices and a full loaf will have 24 slices.
              </Text>
            </Box>
          </Box>
          <Box sx={styles.col}>
            <Image src={img1} width="625" height="719" alt="" />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;

const styles = {
  about: {
    overflow: "hidden",
    pt: ["75px", null, null, null, "0", "80px"],
    pb: ["50px", null, null, null, null, "80px", "140px"],
  },
  row: {
    display: "grid",
    gridGap: [0, null, null, null, "25px", null, "55px"],
    gridTemplateColumns: ["1fr", null, null, null, "1fr 1fr"],
  },
  col: {
    img: {
      maxWidth: ["100%", null, null, "60%", "100%", "none"],
      mx: [null, null, null, "auto", "0"],
      display: [null, null, null, "block"],
      mt: [null, null, null, null, "40px", "0"],
    },
  },
  content: {
    pt: [0, null, null, null, "10px", "25px"],
    mb: [null, null, null, "-40px", "0"],
    position: "relative",
    zIndex: 10,
    paddingLeft: ["12px", null, null, null, "0"],
    paddingRight: ["12px", null, null, null, "0", "75px", "0"],
    pb: ["10px"],
    maxWidth: [null, null, null, "590px", null, "100%"],
    width: ["100%"],
    mx: [null, null, null, "auto", null, "0"],
    textAlign: ["center", null, null, null, "left"],
    h3: {
      fontSize: ["18px", null, null, null, null, "20px"],
      maxWidth: [null, null, null, "400px", "none"],
      mx: [null, null, null, "auto", "0"],
      color: "black",
      fontWeight: "bold",
      letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
      lineHeight: [1.5, null, 1.25],
      mb: ["30px", null, null, null, "30px"],
    },
    p: {
      fontSize: [0, null, 2, null, "17px"],
      color: "text",
      lineHeight: ["26px", null, null, 1.8, null, 2.06],
      "+p": {
        mt: ["15px", null, null, null, "15px"],
      },
    },
  },
  specialText: {
    color: "heading",
    opacity: 0.6,
  },
  link: {
    color: "primary",
    fontSize: [1, null, 2],
    display: "inline-block",
    verticalAlign: "middle",
    fontWeight: "bold",
    mt: ["10px", null, null, null, "10px"],
    svg: {
      position: "relative",
      top: "3px",
    },
  },
};
