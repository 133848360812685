import React from "react";
import { Box } from "theme-ui";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import BlockTitle from "components/block-title";
import TestimonialsCard from "components/testimonial-card";

SwiperCore.use([Autoplay]);

const TESTIMONIALS_DATA = {
  title: "What our customers say about our bread",
  testimonials: [
    [
      {
        text: "I had to write to you and let you know that your bread is amazing! It's absolutely delicious!! I've had the last 2 days and it's spot on! Great taste, nice texture and overall a great balance of the ingredients. Bravo! Thank you so much for giving me a sample! I can't wait to have more 😊",
        name: "Catherine Z.",
      },
      {
        text: "I just want to give you feedback and let you know the bread you gave us on Saturday was amazing! It's already gone, it didn't last long enough to freeze. My daughter said it's the best gluten free bread ever. So thank you for sharing!",
        name: "Stacy R.",
      },
    ],
    [
      {
        text: "I love this gluten-free bread, it tastes like heaven! It's great toasted, and sliced thin, with butter on it. It tastes like a true work of deliciousness. It's my favorite bread!",
        name: "Neela R.",
      },
      {
        text: "We made toast this morning and it was excellent! Definitely could taste the signature sour flavor of sourdough bread. The crust crisped up nicely and the texture was the right amount of chewy you often get with sourdough.",
        name: "Suzanne R.",
      },
    ],
    [
      {
        text: "Okay - we loved the bread!  Toasted was my favorite. Perfect texture and crunch when toasted. Nice and light (which is hard to achieve with GF). My whole fam loved it. I also love that you use organic ingredients!",
        name: "Keeli K.",
      },
      {
        text: "My daughter who is gluten free really enjoys how soft and moist this bread is! She gets so excited when we toast up a fresh slice.",
        name: "Danielle R.",
      },
    ],
  ],
};

const Testimonials = () => {
  const testimonialCarousel = {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    speed: 1000,
    centeredSlides: true,
    autoHeight: true,
    autoplay: {
      waitForTransition: false,
      delay: 10000,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  };

  const { testimonials, title } = TESTIMONIALS_DATA;
  return (
    <Box as="section" sx={styles.section}>
      <BlockTitle title={title} />
      <Swiper {...testimonialCarousel}>
        {testimonials.map((item, index) => (
          <SwiperSlide key={index}>
            {item.map(({ text, name }, _index) => (
              <TestimonialsCard text={text} name={name} key={_index} />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Testimonials;

const styles = {
  container: {
    position: "relative",
  },
  section: {
    backgroundColor: "#F4F4F6",
    pt: ["80px", null, null, null, "80px", null, "100px"],
    pb: ["60px", null, null, null, "80px", null, "120px"],
  },
};
