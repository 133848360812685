import React from "react";
import { Box, Container, Heading, Text, Link } from "theme-ui";

const ORDER_DATA = {
  title: "Want to try our baked goods?",
  text: "Order now from Hotplate",
  button: {
    link: "https://hotplate.com/josephinesbakehouse",
    label: "Order Now",
  },
};

const Order = () => {
  const { title, text, button } = ORDER_DATA;
  return (
    <Box as="section" id="order" sx={styles.section}>
      <Container>
        <Heading as="h2">{title}</Heading>
        <Text as="p">{text}</Text>
        <Link sx={styles.button} href={button.link} target="_blank">
          {button.label}
        </Link>
      </Container>
    </Box>
  );
};

export default Order;

const styles = {
  section: {
    pb: ["70px", null, "80px", null, "95px", null, "110px"],
    pt: ["65px", null, "75px", null, "85px", null, "95px"],
    backgroundColor: "heading_secondary",
    textAlign: "center",
    h2: {
      fontFamily: "archivo",
      fontSize: ["24px", "28px", "32px", "36px", null, "40px"],
      fontWeight: 600,
      lineHeight: 1.4,
      color: "#EDF5FF",
      letterSpacing: "-1px",
      mb: ["12px", null, "15px", null, "20px"],
    },
    p: {
      fontSize: ["15px", null, "16px", null, "18px"],
      lineHeight: [1.9, null, 2],
      color: "#ffffff",
      maxWidth: "505px",
      mx: "auto",
      mb: ["25px", null, "30px", "35px", null, "45px"],
    },
  },
  button: {
    color: "heading_secondary",
    letterSpacing: " -0.16px",
    fontSize: ["13px", "14px", "15px"],
    padding: ["14px 20px 13px", "14px 25px 13px", "17px 25px 15px"],
    lineHeight: 1,
    fontWeight: 700,
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
  },
};
