/** @jsx jsx */
import { jsx, Box, Flex, Image, Heading, Text } from "theme-ui";
import { rgba } from "polished";

const MenuCard = ({ post }) => {
  const { thumb, title, items } = post;
  return (
    <Box sx={styles.post}>
      <Flex as="figure" sx={styles.postImage}>
        <Image src={thumb} alt={title} />
      </Flex>
      <Box sx={styles.meta}>
        <Heading as="h3">{title}</Heading>
        <ul sx={styles.menuList}>
          {items.map((item) => (
            <li>
              <Text as="span" sx={styles.menuItems}>
                {item}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default MenuCard;

const styles = {
  post: {
    m: [0, 0, 0, "0 15px", 0],
    figure: {
      alignItems: "flex-start",
      mb: ["10px", null, null, "15px", "25px"],
    },
    h3: {
      fontWeight: 500,
      fontSize: [18, null, null, null, null, null, 20],
      lineHeight: 1.5,
      a: {
        cursor: "pointer",
        color: "text",
        textDecoration: "none",
      },
    },
  },
  menuList: {
    m: 0,
    px: 3,
    py: 3,
  },
  menuItems: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.6,
    color: rgba("#343D48", 0.8),
    img: {
      mr: "8px",
    },
  },
};
